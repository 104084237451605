import { template as template_e8b474744322470f88e2573198798374 } from "@ember/template-compiler";
const FKLabel = template_e8b474744322470f88e2573198798374(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
