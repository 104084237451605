import { template as template_d396013b98394b7c91c18e56082f583b } from "@ember/template-compiler";
const FKControlMenuContainer = template_d396013b98394b7c91c18e56082f583b(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
